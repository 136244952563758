import pica from 'pica'

async function processImage(file) {
  // Compress image
  const picaInstance = pica()
  const img = document.createElement('img')
  img.src = URL.createObjectURL(file)

  await new Promise((resolve, reject) => {
    img.onload = resolve
    img.onerror = reject
  })

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0)
  const width = img.width
  const height = img.height
  canvas.width = width
  canvas.height = height
  
  // Resize image if too large
  const maxSize = 2000
  if(width > maxSize || height > maxSize) {
    const ratio = width / height
    if(ratio > 1) {
      canvas.width = maxSize
      canvas.height = maxSize / ratio
    } else {
      canvas.width = maxSize * ratio
      canvas.height = maxSize
    }
  }

  const compressed = await picaInstance.resize(img, canvas, { quality: 0.8 })
  const blob = await picaInstance.toBlob(compressed, file.type)
  const compressedFile = new File([blob], file.name, { type: file.type })
  
  // Compare image size to original and use the smaller one
  const compressedSize = compressedFile.size
  const originalSize = file.size
  const useCompressed = compressedSize < originalSize

  console.log('Original size: ' + originalSize + ' bytes', 'Compressed size: ' + compressedSize + ' bytes', 'Using compressed: ' + useCompressed, 'Width: ' + canvas.width, 'Height: ' + canvas.height)

  return useCompressed ? compressedFile : file
}

export default processImage;
